import { createSelector } from 'reselect'
import {
  all,
  allPass,
  any,
  complement,
  compose,
  contains,
  defaultTo,
  filter,
  find,
  groupBy,
  has,
  head,
  isNil,
  length,
  lt,
  map,
  pathOr,
  prop,
  propEq,
  propOr,
  reduce,
  values,
  pipe,
} from 'ramda'
import { isLaaksaareSail } from './selectors.util'
import { getSailDate, getSelectedSailPackageFirstRoute } from '../user-selections'
import { checkIfBookingOutOfRange } from '../inventory/utils'
import { filterFeeItems } from './utils'

export const getReservation = prop('reservation')
const allSeqNums = map(prop('seqN'))
const allPlateNums = map(prop('licencePlate'))
const numsOfType = (type = 'guests') => compose(allSeqNums, propOr([], type))
const plateNumsOfType = (type = 'guests') => compose(allPlateNums, propOr([], type))
const numsOfBicycleType = (type = 'vehicles') =>
  compose(allSeqNums, filter(propEq('type', 'BICYCLE')), propOr([], type))

const isVehicle = propEq('type', 'VEHICLE')
const isPassenger = propEq('type', 'PASSENGER')
const inventoryClassBicycle = propEq('inventoryClass', 'BICYCLE')

const isSubTypeTrailer = propEq('priceCategorySubType', 'TRAILER')
const isTrailer = allPass([isVehicle, isSubTypeTrailer])

const passengersExistInItems = compose(lt(0), length, filter(isPassenger), defaultTo([]))

const allTripsIncludePassengers = compose(
  all((b) => b === true),
  map(passengersExistInItems),
  values,
  groupBy(prop('sailPackageSeqN')),
  defaultTo([])
)

const getOnlyVehicles = (vehicles = [], vehiclesItems = []) => {
  const vehiclesOwnerSeqNs = map(prop('ownerSeqNs'), vehiclesItems)
  const itemByOwnerSeqN = (vehicle) => find(contains(prop('seqN', vehicle)))(vehiclesOwnerSeqNs)

  return filter(itemByOwnerSeqN, vehicles)
}

const onlyVehicleItems = compose(
  filter(allPass([isVehicle, complement(isSubTypeTrailer), complement(inventoryClassBicycle)])),
  propOr([], 'items')
)

export const getFirstVehicleItem = createSelector(onlyVehicleItems, head)

export const vehiclesForConfirm = createSelector([prop('vehicles'), onlyVehicleItems], getOnlyVehicles)

export const getFirstVehicleFromReservation = createSelector(vehiclesForConfirm, head)

const onlyTrailerItems = compose(filter(allPass([isTrailer, complement(inventoryClassBicycle)])), propOr([], 'items'))

export const getFirstTrailerItem = createSelector(onlyTrailerItems, head)

export const trailersForConfirm = createSelector([prop('vehicles'), onlyTrailerItems], getOnlyVehicles)

export const getFirstTrailerFromReservation = createSelector(trailersForConfirm, head)

export const createSailPackagesMapOfSeqNums = reduce(
  (acc, { code, seqN }) => ({
    ...acc,
    [code]: seqN,
  }),
  {}
)

const getFirstObjectOrEmpty = compose(defaultTo({}), head, values)

export const getPayedReservations = createSelector(getReservation, prop('payedReservations'))
export const getPayedReservation = (state) => (token) => createSelector(getPayedReservations, propOr({}, token))(state)
export const getFirstPayedReservation = createSelector(getPayedReservations, getFirstObjectOrEmpty)
export const getRefundReservation = createSelector(getReservation, propOr({}, 'refund'))
export const getEditReservation = createSelector(getReservation, pathOr({}, ['edit', 'reservation']))
export const getEditReservationMode = createSelector(getReservation, pathOr('', ['edit', 'mode']))
export const getCurrentReservation = createSelector(getReservation, propOr({}, 'current'))
export const getCurrentReservationOrNull = createSelector(getReservation, prop('current'))
export const getOriginalReservation = createSelector(getReservation, pathOr({}, ['edit', 'originalReservation']))
export const isReservationEmpty = createSelector(getCurrentReservation, isNil)
export const isFetching = createSelector(getReservation, prop('fetching'))
export const getPriceReadidenss = createSelector(getReservation, prop('isPriceReady'))
export const getReservationFromReserve = createSelector(getReservation, prop('isReservationForDriverFromReserve'))
export const getModifying = createSelector(getReservation, prop('modifying'))
export const getCurrentReservationId = createSelector(getCurrentReservation, prop('reservationId'))
export const getGuestNumbers = createSelector(getCurrentReservation, numsOfType('guests'))
export const getVehicleNumbers = createSelector(getCurrentReservation, numsOfType('vehicles'))
export const getVehiclePlateNumbers = createSelector(getCurrentReservation, plateNumsOfType('vehicles'))
export const getBicycleNumbers = createSelector(getCurrentReservation, numsOfBicycleType())
export const getAddonNumbers = (currentReservation, code) => {
  return compose(allSeqNums, filter(propEq('code', code)), propOr([], 'addons'))(currentReservation)
}
export const getItems = createSelector(getCurrentReservation, pipe(propOr([], 'items'), filterFeeItems))
export const getSailPackages = createSelector(getCurrentReservation, propOr([], 'sailPackages'))
export const getEditReservationSailPackages = createSelector(getEditReservation, propOr([], 'sailPackages'))
export const getCodeSeqNumsMap = createSelector(getSailPackages, createSailPackagesMapOfSeqNums)
export const getGuests = createSelector(getCurrentReservation, prop('guests'))
export const getErrorDetails = createSelector(getReservation, propOr({}, 'errorDetails'))
export const areTherePassengersInReservation = createSelector(getItems, allTripsIncludePassengers)
export const selectPassengers = createSelector(getItems, filter(propEq('type', 'PASSENGER')))
export const getIsEditProcessStarted = createSelector(getReservation, pathOr(false, ['edit', 'started']))

export const getCurrentReservationPromotion = createSelector(getCurrentReservation, prop('promotion'))

export const isCurrentResLaaksaareRoute = createSelector(getSailPackages, isLaaksaareSail)
export const isEditResLaaksaareRoute = createSelector(getEditReservationSailPackages, isLaaksaareSail)

export const filteredGuestsForItems =
  (guests = []) =>
  (items = []) => {
    const guestsInItems = map(prop('ownerSeqNs'), items)
    const guestForTrip = ({ seqN }) => any((itemSeqNs = []) => contains(seqN, itemSeqNs))(guestsInItems)
    const tripGuests = filter(guestForTrip, guests)

    return tripGuests
  }

const hasIdNum = has('personalIdentificationNumber')
export const findIdNums = compose(map(prop('personalIdentificationNumber')), filter(hasIdNum))
export const getReservationIdFromUrl = pathOr('', ['match', 'params', 'reservationId'])

const getCurrentPaymentInfo = createSelector(getCurrentReservation, prop('paymentInfo'))
export const getTotalPrice = createSelector(getCurrentPaymentInfo, pathOr(0, ['totalPrice', 'amount']))
export const getCurrency = createSelector(getCurrentPaymentInfo, pathOr('', ['totalPrice', 'currency']))

export const paymentMethodsSelector = createSelector(getReservation, prop('paymentMethods'))

export const getBookingConditionsForRoute = (state) => {
  const bookingAttribute = map(
    (item) => ({
      code: item.attribute.code,
      value: pathOr('', ['structure', 'value', 'value'])(item) || pathOr('', ['structure', 'values'])(item),
    }),
    pathOr([], ['routes', `${getSelectedSailPackageFirstRoute(state)}`, 'attributes'])(state)
  ).find((item) => item.code === 'DEADLINE')
  let outOfBookingBoundary = true
  if (bookingAttribute) {
    outOfBookingBoundary = checkIfBookingOutOfRange(bookingAttribute, getSailDate(state))
  }
  return outOfBookingBoundary
}

export const getShouldForbidClosingModals = createSelector(getReservation, propOr(false, 'shouldForbidClosingModals'))
